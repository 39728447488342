.main-navigation{

	ul{
		list-style-type: none;
		padding-left: 0;
		margin: 0;
	}

	.grid-container{
		.coluna{
			&.esq{
				display: flex;
				align-items: center;

				.texto{
					font-family: $ubu-bold;
					color: #000;
					line-height: 1.2;
					font-size: 24px;
					width: 250px;
					max-width: 100%;
					text-align: center;
				}
			}
			&.dir{
				.telefone{
					font-family: $ubu-bold;
					color: #000;
					line-height: 1.2;
				}
				.distribuidores{
					font-size: 14px;
					font-family: $ubu-italic;
				}
			}
		}
	}
	//grid-container

	.menu{
		font-family: $ubu-bold;
		font-size: 15px;
		line-height: 1;
	}

	@include media-breakpoint-only(xl) {
		width: 100%;
		max-width: 1140px;
		margin-left: auto;
		margin-right: auto;
		padding-left: 15px;
		padding-right: 15px;
		padding-top: 20px;
		
		.grid-container{
			padding-bottom: 20px;
			display: grid;
			grid-template-columns: calc(calc(100% - 208px)*0.5) 208px calc(calc(100% - 208px)*0.5);

			.coluna{
				&.dir{
					display: flex;
					flex-direction: column;
					justify-content: space-around;

					.telefone{
						text-align: right;
						font-size: 34px;

						.texto{
							font-size: 24px;
						}
					}
					.distribuidores{
						width: 100%;
						max-width: 280px;
						margin-left: auto;

						.segura{
							margin-top: 5px;
							display: flex;
							justify-content: space-between;
						}
					}
				}
			}
		}
		//grid-container

		.menu{
			display: flex;
			justify-content: space-between;
			background-color: $laranja;
			color: #000;
			position: relative;

			&:before,
			&:after{
				content: '';
				position: absolute;
				background-color: inherit;
				top: 0;
				width: 100vh;
				height: 100%;
			}
			&:before{
				right: 100%;
			}
			&:after{
				left: 100%;
			}
			
			.li-menu{
				display: flex;

				&.active .item-menu{
					color: #fff;
				}

				.item-menu{
					display: flex;
					align-items: center;
					padding: 19px 15px;
					transition: all 0.2s linear;

					&:hover{
						color: #fff;
					}
				}
			}

			.dropdown{
				position: relative;

				a.fas{
					display: none;
				}

				&:hover{
					color: #fff;
					.dropdown-content{
						display: block;
					}
				}

				.dropdown-content{
					background-color: $laranja;
					position: absolute;
					z-index: 50;
					color: #fff;
					top: 100%;
					left: 50%;
					transform: translateX(-50%);
					width: 100px;
					min-width: 100px;
					will-change: height;

					a{
						display: block;
						padding: 10px 10px;
						transition: all 0.2s linear;

						&:hover{
							background-color: #000;
						}
					}
				}
			}
		}
		
	}
	//DESKTOP

	@include media-breakpoint-down(lg) {
		width: 250px;
		height: 100%;
		background-color: #fff;
		position: fixed;
		z-index: 1000;
		top: 0;
		left: -250px;
		overflow-y: auto;
		border-right: 1px solid #000;
		transition: left 0.4s ease-out;
		display: flex;
		flex-direction: column;

		&.active{
			left: 0;
		}

		.logo{
			display: none;
		}

		.menu{
			order: 1;
			color: #000;

			.li-menu + .li-menu{
				border-top: 1px solid #000;
			}

			.li-menu{
				&.active{
					.item-menu{
						background-color: $laranja;
						color: #fff;
					}					
				}
				.item-menu{
					display: block;
					padding: 8px 0px 8px 10px;
				}
			}			

			.dropdown{
				.item-drop{
					display: flex;
					align-items: center;
					justify-content: space-between;

					a.fas{
						padding: 5px 10px;
						height: 100%;
						font-size: 20px;
						cursor: pointer;
						transform: rotate(0deg);
						transition: all 0.3s ease-out;
					}
					a.fas[aria-expanded="true"] {
						transform: rotate(180deg);
					}
				}
			}
			.dropdown-content{
				background-color: #ddd;
				border-top: 1px solid #000;

				li + li{
					border-top: 1px solid #aaa;
				}

				a{
					display: block;
					padding: 8px 0px 8px 20px;
				}
			}
		}
		.grid-container{
			order: 2;
			text-align: center;
			padding-top: 30px;

			.coluna{
				&.esq{
					margin-bottom: 20px;
					.texto{
						font-size: 18px;
					}
				}
				&.dir{
					.distribuidores{
						.txt{
							margin-top: 20px;
							margin-bottom: 5px;
						}
					}
				}
			}
		}

		
	}

}