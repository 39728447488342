@include media-breakpoint-down(lg) {
	.topo{
		z-index: 5;
		box-shadow: 0 0 6px rgba(#000,0.5);
	}	
}
.mbl-controls{
	width: 100%;
	max-width: 970px;
	padding-left: 15px;
	padding-right: 15px;
	padding-top: 5px;
	padding-bottom: 5px;
	margin-left: auto;
	margin-right: auto;
	position: relative;

	.logo{
		width: 80px;
		height: auto;
		margin-left: auto;
		margin-right: auto;
	}

	.botao{
		width: 60px;
		height: 100%;
		position: absolute;
		top: 0;
		right: 0;
		background-color: $laranja;
		display: flex;
		align-items: center;
		justify-content: center;

		.mbl-menu{
			width: 36px;
			height: 24px;
			cursor: pointer;

			.hamburguer{
				position: absolute;
				width: 36px;
				height: 4px;
				border-radius: 4px;
				background-color: #fff;
				transition: all 0.2s ease;
				display: block;

				&:before,
				&:after{
					position: absolute;
					width: 36px;
					height: 4px;
					border-radius: 4px;
					background-color: #fff;
					transition: all 0.2s ease;
					display: block;
					content: '';
				}
				&:before{
					top: 10px;
					transition: top .1s cubic-bezier(.33333,.66667,.66667,1) .2s,transform .13s cubic-bezier(.55,.055,.675,.19);
				}
				&:after{
					top: 20px;
					transition: top .2s cubic-bezier(.33333,.66667,.66667,1) .2s,transform .13s cubic-bezier(.55,.055,.675,.19);
				}
			}
			&.active{
				.hamburguer{
					// transition-delay: .22s;
					background-color: transparent !important;

					&:before{
						top: 0;
						transition: top .1s cubic-bezier(.33333,0,.66667,.33333) .15s,transform .13s cubic-bezier(.215,.61,.355,1) .22s;
						transform: translate3d(0,10px,0) rotate(45deg);
					}
					&:after{
						top: 0;
					    transition: top .2s cubic-bezier(.33333,0,.66667,.33333),transform .13s cubic-bezier(.215,.61,.355,1) .22s;
					    transform: translate3d(0,10px,0) rotate(-45deg);
					}
				}
			}
		}
	}

	@include media-breakpoint-only(xl) {
		display: none;
	}
	@include media-breakpoint-only(lg) {
		max-width: 970px;
	}
	@include media-breakpoint-only(md) {
		max-width: 720px;
	}
	@include media-breakpoint-only(sm) {
		max-width: 540px;
	}

}