@font-face {
    font-family: 'open_sansregular';
    src: url('../fonts/opensans-regular/opensans-regular-webfont.eot');
    src: url('../fonts/opensans-regular/opensans-regular-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/opensans-regular/opensans-regular-webfont.woff2') format('woff2'),
         url('../fonts/opensans-regular/opensans-regular-webfont.woff') format('woff'),
         url('../fonts/opensans-regular/opensans-regular-webfont.ttf') format('truetype'),
         url('../fonts/opensans-regular/opensans-regular-webfont.svg#open_sansregular') format('svg');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'ubuntuitalic';
    src: url('../fonts/ubuntu-italic/ubuntu-italic-webfont.eot');
    src: url('../fonts/ubuntu-italic/ubuntu-italic-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/ubuntu-italic/ubuntu-italic-webfont.woff2') format('woff2'),
         url('../fonts/ubuntu-italic/ubuntu-italic-webfont.woff') format('woff'),
         url('../fonts/ubuntu-italic/ubuntu-italic-webfont.ttf') format('truetype'),
         url('../fonts/ubuntu-italic/ubuntu-italic-webfont.svg#ubuntuitalic') format('svg');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'ubuntu_mediumregular';
    src: url('../fonts/ubuntu-medium/ubuntu-medium-webfont.eot');
    src: url('../fonts/ubuntu-medium/ubuntu-medium-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/ubuntu-medium/ubuntu-medium-webfont.woff2') format('woff2'),
         url('../fonts/ubuntu-medium/ubuntu-medium-webfont.woff') format('woff'),
         url('../fonts/ubuntu-medium/ubuntu-medium-webfont.ttf') format('truetype'),
         url('../fonts/ubuntu-medium/ubuntu-medium-webfont.svg#ubuntu_mediumregular') format('svg');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'ubuntubold';
    src: url('../fonts/ubuntu_bold/ubuntu-bold-webfont.eot');
    src: url('../fonts/ubuntu_bold/ubuntu-bold-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/ubuntu_bold/ubuntu-bold-webfont.woff2') format('woff2'),
         url('../fonts/ubuntu_bold/ubuntu-bold-webfont.woff') format('woff'),
         url('../fonts/ubuntu_bold/ubuntu-bold-webfont.ttf') format('truetype'),
         url('../fonts/ubuntu_bold/ubuntu-bold-webfont.svg#ubuntubold') format('svg');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'ubunturegular';
    src: url('../fonts/ubuntu_regular/ubuntu-regular-webfont.eot');
    src: url('../fonts/ubuntu_regular/ubuntu-regular-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/ubuntu_regular/ubuntu-regular-webfont.woff2') format('woff2'),
         url('../fonts/ubuntu_regular/ubuntu-regular-webfont.woff') format('woff'),
         url('../fonts/ubuntu_regular/ubuntu-regular-webfont.ttf') format('truetype'),
         url('../fonts/ubuntu_regular/ubuntu-regular-webfont.svg#ubunturegular') format('svg');
    font-weight: normal;
    font-style: normal;
}