.faixa-topo{
	background-color: #000;
	color: #fff;
	font-family: $ubu-italic;
	line-height: 1;
	font-size: 14px;
	padding-top: 5px;
	padding-bottom: 5px;

	.mais-maq{
		a{
			&:hover{
				text-decoration: underline;
			}
		}
	}

	@include media-breakpoint-up(md) {
		.container{
			display: flex;
			align-items: center;
			justify-content: space-between;

			.direita{
				display: flex;
				align-items: center;

				.mais-maq{
					margin-right: 15px;
				}
			}
		}
	}
	@include media-breakpoint-down(sm) {
		text-align: center;
		.mais-maq{
			margin: 5px 0;

			a{
				color: $laranja;
				font-weight: bold;
			}
		}
		.redes-sociais{
			justify-content: center;
		}
	}
}
//faixa-topo