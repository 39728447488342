.card-numeros{
	width: 174px;
	max-width: 100%;
	margin-left: auto;
	margin-right: auto;
	text-align: center;
	color: #fff;
	font-family: $ubu-bold;

	svg{
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;

		circle{
			stroke-dasharray: 1000;
			stroke-dashoffset: 1000;
			transform: rotate(-90deg);
			transform-origin: center center;
			
		}
	}

	.circulo{
		position: relative;
		height: 174px;
		max-height: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
		margin-bottom: 20px;

		.number{
			position: relative;
			z-index: 10;
			font-size: 50px;

			span{
				line-height: 1;
			}

			&:before{
				content: '+';
				position: absolute;
				top: 5px;
				left: -20px;
				font-size: 30px;				
			}
		}
	}

	.title{
		font-size: 18px;
		line-height: 1;
	}	

	&.active{
		svg{
			circle{
				stroke-dashoffset: 0;
				transition: all 7s linear;
			}
		}
	}
}
//.card-numeros

@include media-breakpoint-up(lg) {
	.col-lg-3:first-child{
		.card-numeros{
			svg{
				circle{
					transition-delay: 0s;
				}
			}
		}
	}
	.col-lg-3:nth-child(2){
		.card-numeros{
			svg{
				circle{
					transition-delay: 0.1s;
				}
			}
		}
	}
	.col-lg-3:nth-child(3){
		.card-numeros{
			svg{
				circle{
					transition-delay: 0.2s;
				}
			}
		}
	}
	.col-lg-3:last-child{
		.card-numeros{
			svg{
				circle{
					transition-delay: 0.3s;
				}
			}
		}
	}
}

@include media-breakpoint-between(sm, md) {
	.col-lg-3:first-child{
		margin-bottom: 30px;
	}
	.col-lg-3:nth-child(2){
		margin-bottom: 30px;
	}
}
@include media-breakpoint-only(xs) {
	.col-lg-3 + .col-lg-3{
		margin-top: 30px;
	}
}
//card-numeros

.card-produtos{
	width: 350px;
	max-width: 100%;
	margin-left: auto;
	margin-right: auto;
	position: relative;

	&.das-internas{
		width: 255px;
		margin-bottom: 30px;

		.content{
			padding: 15px;
			border-top: 7px solid #DDD;
			border-bottom: 7px solid #DDD;
			transition: all 0.4s linear;
		}
	}

	.img{
		border: 1px solid #ededed;
	}

	.content{
		text-align: center;
		position: relative;
		z-index: 2;
		background-color: #fff;
		border-top: 7px solid $laranja;
		border-bottom: 7px solid $laranja;
		border-right: 1px solid #ededed;
		border-left: 1px solid #ededed;
		color: #000;
		padding: 30px 15px;

		.title{
			font-size: 16px;
			line-height: 1.2;
			font-family: $ubu-bold;
			margin-bottom: 5px;
			overflow: hidden;
			max-height: 100%;
			display: -webkit-box;
			-webkit-box-orient: vertical;
			height: 38px;
			line-clamp: 2;
			-webkit-line-clamp: 2;
		}
		.text{
			font-size: 15px;
			line-height: 1;
			overflow: hidden;
			max-height: 100%;
			display: -webkit-box;
			-webkit-box-orient: vertical;			
		}
	}

	@include media-breakpoint-only(xl) {
		&.das-internas{
			width: 255px;
			.content{
				transform: scale(1);
				width: 100%;
				margin-top: 0;
			}

			&:hover{
				.content{
					border-top-color: $laranja;
					border-bottom-color: $laranja;
				}
			}
		}

		&:hover{
			.content{
				transform: scale(1);
			}
		}
		.content{
			margin-top: -110px;
			margin-right: auto;
			margin-left: auto;
			width: 75%;
			transform: scale(0);
			transition: all 0.3s ease-out;

			.text{
				height: 90px;
				line-clamp: 6;
				-webkit-line-clamp: 6;
			}
		}
	}
	@include media-breakpoint-only(lg) {
		.content{
			.text{
				height: 75px;
				line-clamp: 5;
				-webkit-line-clamp: 5;
			}
		}
	}
	@include media-breakpoint-only(md) {
		.content{
			.text{
				height: 60px;
				line-clamp: 4;
				-webkit-line-clamp: 4;
			}
		}
	}
	@include media-breakpoint-only(sm) {
		.content{
			.text{
				height: 90px;
				line-clamp: 6;
				-webkit-line-clamp: 6;
			}
		}
	}
	@include media-breakpoint-only(sm) {
		.content{
			.text{
				height: 90px;
				line-clamp: 6;
				-webkit-line-clamp: 6;
			}
		}
	}
	@include media-breakpoint-only(xs) {
		.content{
			.text{
				height: 75px;
				line-clamp: 5;
				-webkit-line-clamp: 5;
			}
		}
	}
}
//.card-produtos

.card-img-maquina{
	width: 445px;
	max-width: 100%;
	margin-left: auto;
	margin-right: auto;

	&:hover{
		.lazyimage{

			&:before{
				opacity: 1;
			}
			&:after{
				transform: translate(-50%, -50%) scale(1);
			}
		}
	}
	.lazyimage{
		position: relative;

		&:before{
			content: '';
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			background-color: rgba(0,0,0,0.4);
			z-index: 2;
			opacity: 0;
			transition: all 0.4s ease-out;
		}
		&:after{
			content: "\f067";
		    font-family: "Font Awesome 5 Free";
		    font-weight: 900;
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%) scale(0);
			color: #fff;
			font-size: 36px;
			z-index: 3;
			transition: all 0.4s ease-out;
		}
	}
}

.card-tipo-maquina{
	color: #000;

	.title{
		font-size: 34px;
		font-family: $ubu-bold;
		line-height: 1;
	}

	.body{
		background-color: #000;
		color: #fff;

		.text{
			font-size: 14px;
			line-height: 1.1;
			margin-bottom: 25px;
			overflow: hidden;
			max-height: 100%;
			display: -webkit-box;
			-webkit-box-orient: vertical;
		}
	}

	@include media-breakpoint-up(lg) {
		.title{
			margin-left: 95px;
			margin-bottom: 5px;
			text-overflow: ellipsis;
			overflow: hidden;
			white-space: nowrap;
			max-width: 100%;
		}
		.body{
			.text{
				height: 150px;
				line-clamp: 10;
				-webkit-line-clamp: 10;
			}
		}
	}

	@include media-breakpoint-only(xl) {
		.title{
			width: 450px;
		}
		.body{
			padding: 30px 30px 25px 120px;
		}
	}
	@include media-breakpoint-only(lg) {
		.title{
			width: 300px;
		}
		.body{
			padding: 30px;
		}
	}
	@include media-breakpoint-between(sm, md) {
		.title{
			width: 450px;
			text-overflow: ellipsis;
			overflow: hidden;
			white-space: nowrap;
			max-width: 100%;
		}
		.body{
			padding: 30px 40px;
			// .text{
			// 	height: 150px;
			// 	line-clamp: 10;
			// 	-webkit-line-clamp: 10;
			// }
		}
	}
	@include media-breakpoint-only(md) {
		.body{
			.text{
				height: 120px;
				line-clamp: 8;
				-webkit-line-clamp: 8;
			}
		}
	}
	@include media-breakpoint-only(sm) {
		.body{
			.text{
				height: 165px;
				line-clamp: 11;
				-webkit-line-clamp: 11;
			}
		}
	}
	@include media-breakpoint-only(xs) {
		.body{
			padding: 15px;

			.btn-padrao{
				margin-left: auto;
				margin-right: auto;
			}
		}
	}
}
//.card-tipo-maquina

.card-pdf{
	border: 1px solid #ededed;
	padding: 25px 20px 15px 20px;
	text-align: center;
	position: relative;
	width: 100%;
	max-width: 255px;
	margin: 0 auto 30px auto;

	&:before,
	&:after{
		content: '';
		position: absolute;
		width: 100%;
		height: 0px;
		left: 0;
		background-color: $laranja;
		transition: height 0.3s linear;
	}
	&:before{
		top: 0;
	}
	&:after{
		bottom: 0;
	}

	.nome{
		font-size: 16px;
		line-height: 1;
		color: #202020;
		font-family: $ubu-bold;
		margin-top: 20px;
		margin-bottom: 15px;
		overflow: hidden;
		max-height: 100%;
		display: -webkit-box;
		-webkit-box-orient: vertical;
		height: 32px;
		line-clamp: 2;
		-webkit-line-clamp: 2;
	}

	.texto{
		font-size: 15px;
		line-height: 1;
		margin-bottom: 15px;
		overflow: hidden;
		max-height: 100%;
		display: -webkit-box;
		-webkit-box-orient: vertical;

		@include media-breakpoint-up(md) {
			height: 75px;
			line-clamp: 5;
			-webkit-line-clamp: 5;
		}
		@include media-breakpoint-only(sm) {
			height: 90px;
			line-clamp: 6;
			-webkit-line-clamp: 6;
		}
		@include media-breakpoint-only(xs) {
			height: 75px;
			line-clamp: 5;
			-webkit-line-clamp: 5;
		}
	}

	.botao{
		font-size: 14px;
		font-family: $ubu-bold;
		line-height: 1;
		display: flex;
		align-items: center;
		justify-content: center;
		position: relative;
		border-radius: 17px;
		transition: all 0.3s linear;
		border: none;
		width: 134px;
		height: 34px;
		background-color: #000;
		color: #fff;
		margin-left: auto;
		margin-right: auto;
		outline: none;

		&:before{
			content: '';
			position: absolute;
			top: -3px;
			left: -3px;
			width: calc(100% + 6px);
			height: calc(100% + 6px);
			border-radius: 20px;
			transition: all 0.3s linear;
			border: 1px solid #000;
		}
	}

	&:hover{
		&:before,
		&:after{
			height: 5px;
		}
		.botao{
			background-color: $laranja;
			&:before{
				border-color: $laranja;
			}
		}
	}
}
//card-pdf

.card-locacao{
	margin-bottom: 30px;

	.title{
		font-size: 24px;
		color: #202020;
		font-family: $ubu-bold;
		margin-bottom: 10px;
		line-height: 1;
	}

	@include media-breakpoint-only(xs) {
		width: 255px;
		max-width: 100%;
		margin-left: auto;
		margin-right: auto;
		text-align: center;

	}
}
//.card-locacao