.paginacao-padrao{
	list-style-type: none;
	padding-left: 0;
	margin-bottom: 0;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-wrap: wrap;
	margin-top: 10px;

	li + li{
		margin-left: 5px;
	}
	li.active a{
		background-color: $laranja;
	}

	span{
		font-size: 10px;
		padding-top: 0px !important;
		padding-bottom: 2px;
	}

	a{
		height: 30px;
		width: 30px;
		display: flex;
		align-items: center;
		justify-content: center;
		background-color: #000;
		color: #fff;
		font-size: 16px;
		font-family: $ubu-bold;
		line-height: 1;
		border-radius: 50%;
		padding-top: 2px;
		transition: all 0.3s linear;

		&:hover{
			background-color: $laranja;
		}
	}

	
}