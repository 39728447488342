//REUTILIZAVEIS
.carousel-indicators{
	li{
		opacity: 1;
		background-color: $laranja;
		width: 6px;
		height: 6px;
		border-radius: 3px;
		border: none;
		transition: width 0.4s ease-out;
		position: relative;
		margin-left: 5px;
		margin-right: 5px;

		&.active{
			width: 50px;
		}

		&:before{
			content: '';
			position: absolute;
			height: 115%;
			width: 115%;
			background-color: transparent;
			top: 0;
			left: 0;
			z-index: 2;
		}
	}
}
//.carousel-indicators

.controles{

	.control{
		position: absolute;
		top: 0;
		transform: translateY(-50%);
		background-color: #ededed;
		color: #111;
		width: 25px;
		height: 25px;
		font-size: 12px;
		display: flex;
		align-items: center;
		justify-content: center;
		border: 1px solid #000;
		border-radius: 50%;
		box-shadow: 0 0 0 6px #ededed;
		transition: all 0.4s ease-out;

		&.prev{
			left: 0;
		}
		&.next{
			right: 0;
		}

		&:hover{
			background-color: $laranja;
			box-shadow: 0 0 0 6px $laranja;
			color: #fff;
			border-color: #fff;
		}
	}
}
//controles
//REUTILIZAVEIS

.banners{
	width: 1920px;
	max-width: 100%;
	overflow: hidden;
	margin-left: auto;
	margin-right: auto;

	@include media-breakpoint-only(xl) {
		.controles{
			width: 1140px;
		}
		.carousel-indicators{
			bottom: 50px;
		}
	}
	@include media-breakpoint-only(lg) {
		.controles{
			width: 970px;
		}
		.carousel-indicators{
			bottom: 25px;
		}
	}
	@include media-breakpoint-only(md) {
		.controles{
			width: 720px;
		}
		.carousel-indicators{
			bottom: 20px;
		}
	}
	@include media-breakpoint-only(sm) {
		.controles{
			width: 540px;
		}
		.carousel-indicators{
			bottom: 15px;
		}
	}
	@include media-breakpoint-only(xs) {
		.controles{
			display: none;
		}
		.carousel-indicators{
			bottom: 10px;
		}
	}

	.controles{
		position: absolute;
		z-index: 3;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
	}
	//controles

	.carousel-indicators{
		z-index: 3;
		margin-bottom: 0;
	}
	//.carousel-indicators
}
//banners

.sobre-nos{

	.desc{
		margin-bottom: 30px;
	}

	@include media-breakpoint-only(xl) {
		.h1-fundo{
			left: -25px;
			font-size: 150px;
		}
		.segura-texto{
			padding-top: 70px;
			width: calc(43% - calc(calc(100vw - 1155px)/2));
		}
		.segura-imagem{
			width: 57%;
		}
	}
	@include media-breakpoint-only(lg) {
		.h1-fundo{
			left: -15px;
			font-size: 120px;
		}
		.h2-padrao{
			font-size: 24px;
		}
		.segura-texto{
			padding-top: 50px;
			padding-bottom: 30px;
			width: calc(43% - calc(calc(100vw - 970px)/2));
		}
		.segura-imagem{
			width: 57%;
		}
	}
	@include media-breakpoint-only(md) {
		.container-fluid{
			max-width: 720px;
			margin-left: auto;
			margin-right: auto;
		}
		.h1-fundo{
			left: -15px;
			font-size: 120px;
		}
		.segura-texto{
			padding-top: 70px;
			padding-bottom: 30px;
			width: 100%;
		}
		.segura-imagem{
			width: 100%;
		}
	}
	@include media-breakpoint-only(sm) {
		.container-fluid{
			max-width: 540px;
			margin-left: auto;
			margin-right: auto;
		}
		.h1-fundo{
			font-size: 100px;
		}
		.segura-texto{
			padding-top: 50px;
			padding-bottom: 30px;
			width: 100%;
		}
		.segura-imagem{
			width: 100%;
		}
	}
	@include media-breakpoint-only(xs) {
		.h1-fundo{
			font-size: 50px;
		}
		.segura-texto{
			padding-top: 30px;
			padding-bottom: 30px;
			width: 100%;

			.btn-padrao{
				margin-left: auto;
				margin-right: auto;
			}
		}
		.segura-imagem{
			width: 100%;
		}
	}
}
//.sobre-nos

.nossos-numeros{
	background-color: $laranja;
	background-image: url('../images/bg-numeros.jpg');
	background-attachment: fixed;
	background-position: center top;
	background-repeat: no-repeat;
	padding-top: 30px;
	padding-bottom: 30px;
}
//.nossos-numeros

.produtos{
	padding-bottom: 40px;

	a.btn-padrao.w134h34.preto{
		width: 150px;
		padding: 5px;
		line-height: 1;
		text-align: center;
	}

	.titulo-fundo{
		.h1-fundo{
			left: 50%;
			transform: translateX(-50%);
		}
	}
	.h2-padrao{
		position: relative;
		z-index: 2;
		text-align: center;
		width: 640px;
		max-width: 100%;
		margin-left: auto;
		margin-right: auto;
	}

	.btn-padrao.preto{
		background-color: transparent;
		border-color: transparent;
		color: #000;
		

		&:before{
			border-color: transparent;
		}

		&:hover,
		&.active{
			background-color: #000;
			border-color: #000;
			color: #fff;

			&:before{
				border-color: #000;
			}
		}
	}
	.botoes{
		position: relative;
		margin-bottom: 30px;

		&:after{
			content: '';
			position: absolute;
			bottom: 0;
			left: 50%;
			transform: translateX(-50%);
			width: 32px;
			height: 4px;
			background-color: $laranja;
			border-radius: 2px;
		}
	}

	#owlProdutos{
		padding-bottom: 50px;
		.owl-nav{
			position: absolute;
			bottom: 0;
			left: 50%;
			transform: translateX(-50%);
			width: 90px;

			.owl-prev,
			.owl-next{
				position: absolute;
				top: 0;
				transform: translateY(-50%);
			}
			.owl-prev{
				left: 0;
			}
			.owl-next{
				right: 0;
			}
		}
	}

	@include media-breakpoint-up(sm) {
		.botoes{
			display: flex;
			align-items: center;
			justify-content: center;
			flex-wrap: wrap;
			padding-bottom: 40px;

			a{
				margin: 0 20px;
			}
		}
	}

	@include media-breakpoint-up(md) {
		.h1-fundo{
			font-size: 150px;
		}
		.h2-padrao{
			padding-top: 80px;
			margin-bottom: 50px;
		}
	}
	@include media-breakpoint-only(sm) {
		.h1-fundo{
			font-size: 100px;
		}
		.h2-padrao{
			padding-top: 50px;
			margin-bottom: 40px;
		}
	}
	@include media-breakpoint-only(xs) {
		.h1-fundo{
			font-size: 60px;
		}
		.h2-padrao{
			font-size: 24px;
			padding-top: 30px;
			margin-bottom: 40px;
		}
		.botoes{
			padding-bottom: 20px;
			a{
				margin: 10px auto;
			}
		}
	}
}
//.produtos

.maquinas{
	.titulos{
		.h1-fundo{
			color: #fff;
		}
	}

	#carouselMaquinas{
		.controles{
			position: absolute;
			z-index: 3;
			bottom: 0;
			left: 50%;
			transform: translateX(-50%);
			width: 90px;
		}
	}

	@include media-breakpoint-up(lg) {
		position: relative;
		padding-bottom: 30px;

		&:before{
			content: '';
			position: absolute;
			top: 0;
			right: 0;
			width: 51%;
			background-color: #ededed;
			display: block;
			height: 100%;
		}
		.titulos{
			margin-left: auto;
			width: 100%;
			max-width: 51%;

			.h1-fundo{
				left: -12px;
				top: -3px;
			}
		}
		.h2-padrao{
			line-height: 1.3;
		}
		#carouselMaquinas{
			padding-top: 50px;
			padding-bottom: 30px;
		}
		#carouselTipoMaquina{
			position: relative;

			.carousel-indicators{
				position: absolute;
				top: 16px;
				right: 0;
				left: auto;
				margin: 0;
			}
		}
	}
	@include media-breakpoint-only(xl) {
		.titulos{
			.h1-fundo{
				font-size: 150px;
			}
		}
		.h2-padrao{
			margin-left: 95px;
			padding-top: 60px;
		}
	}
	@include media-breakpoint-only(lg) {
		.titulos{
			.h1-fundo{
				font-size: 100px;
			}
		}

		.h2-padrao{
			margin-left: 70px;
			padding-top: 40px;
		}		
	}
	@include media-breakpoint-between(sm, md) {
		background-color: #ededed;
		padding-bottom: 30px;

		.titulos{
			.h1-fundo{
				left: 20px;
			}
		}
		#carouselTipoMaquina{
			position: relative;

			.carousel-indicators{
				position: absolute;
				top: 16px;
				right: 0;
				left: auto;
				margin: 0;
			}
		}
		#carouselMaquinas{
			padding-top: 50px;
			padding-bottom: 30px;
		}
	}
	@include media-breakpoint-only(md) {
		.titulos{
			.h1-fundo{
				font-size: 150px;
			}
		}
		.h2-padrao{
			padding-top: 80px;
		}
	}
	@include media-breakpoint-only(sm) {
		.titulos{
			.h1-fundo{
				font-size: 110px;
			}
		}
		.h2-padrao{
			padding-top: 50px;
		}
	}
	@include media-breakpoint-only(xs) {
		background-color: #ededed;
		padding-bottom: 30px;
		.titulos{
			.h1-fundo{
				font-size: 60px;
				left: 15px;
			}
		}
		.h2-padrao{
			padding-top: 30px;
			font-size: 24px;
		}
		#carouselTipoMaquina{
			.carousel-indicators{
				display: none;
			}
		}
		#carouselMaquinas{
			padding-top: 30px;
			padding-bottom: 30px;
		}
	}

}
//.maquinas

.locacao{
	.desc{
		margin-bottom: 30px;
	}

	.video-locacao{
		padding-bottom: 55.90551181102362%;
	}

	@include media-breakpoint-only(xl) {
		padding-bottom: 35px;
		position: relative;

		&:before{
			content: '';
			position: absolute;
			top: 0;
			right: 0;
			width: 44.25%;
			background-color: #000;
			display: block;
			height: 100%;
		}

		.h1-fundo{
			font-size: 150px;
		}
		.content{
			padding-top: 70px;
		}
	}
	@include media-breakpoint-only(lg) {
		position: relative;
		padding-bottom: 30px;

		&:before{
			content: '';
			position: absolute;
			top: 0;
			right: 0;
			width: 44.25%;
			background-color: #000;
			display: block;
			height: 100%;
		}
		.h1-fundo{
			font-size: 120px;
		}
		.h2-padrao{
			font-size: 24px;
		}
		.content{
			padding-top: 50px;
		}
	}
	@include media-breakpoint-between(sm, md) {
		padding-bottom: 40px;
		position: relative;

		&:before{
			content: '';
			position: absolute;
			bottom: 0;
			left: 0;
			height: 44.25%;
			background-color: #000;
			display: block;
			width: 100%;
		}

		.h1-fundo{
			font-size: 150px;
		}
		.content{
			padding-top: 70px;
		}
		.btn-padrao{
			margin-bottom: 30px;
		}
	}
	@include media-breakpoint-only(sm) {
		.h1-fundo{
			font-size: 120px;
		}
	}
	@include media-breakpoint-only(xs) {
		padding-bottom: 30px;

		position: relative;

		&:before{
			content: '';
			position: absolute;
			bottom: 0;
			left: 0;
			height: 25%;
			background-color: #000;
			display: block;
			width: 100%;
		}

		.h1-fundo{
			font-size:70px;
		}
		.content{
			padding-top: 30px;
		}
		.btn-padrao{
			margin: 0 auto 30px auto;
		}
	}
}
//.locacao

.distribuidor{
	background-color: $laranja;
	color: #fff;
	background-repeat: no-repeat;
	background-position: center top;
	background-image: url('../images/bg-distribuidor.png');

	.img{
		.lazyimage{
			margin-left: auto;
			margin-right: auto;
		}
	}

	.h1-distribuidor{
		font-size: 30px;
		font-family: $ubu-bold;
		line-height: 1;
		text-align: right;
		position: relative;
		padding-bottom: 40px;
		margin-bottom: 30px;

		&:after{
			content: '';
			position: absolute;
			display: block;
			width: 32px;
			height: 4px;
			background-color: #fff;
			border-radius: 2px;
			bottom: 0;
			right: 0;
		}
	}

	.desc{
		text-align: right;
		margin-bottom: 30px;
	}

	.btn-padrao{
		margin-left: auto;

		&:hover{
			background-color: #fff;
			color: #000;

			&:before{
				border-color: #fff;
			}
		}
	}

	@include media-breakpoint-up(lg) {
		padding-top: 50px;
		padding-bottom: 50px;
	}
	@include media-breakpoint-down(md) {
		padding-top: 30px;
		padding-bottom: 30px;
		.img{
			margin-bottom: 30px;
		}
	}
	@include media-breakpoint-only(xs) {
		.h1-distribuidor{
			font-size: 24px;
		}
		.btn-padrao{
			margin-right: auto;
		}
	}
}