.rodape{
	padding-top: 65px;
	padding-bottom: 10px;
	background-image: url('../images/bg-rodape.jpg');
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center center;
	position: relative;
	color: #fff;

	&.rodapeInternas{
		&:before{
			background-image: url('../images/detalhe-rodape-internas.png');
		}
		.only-index{
			display: none;
		}
		.only-internas{
			display: block;
		}
	}

	&:before{
		content: '';
		position: absolute;
		top: 0;
		left: 50%;
		transform: translateX(-50%);
		background-repeat: no-repeat;
		z-index: 2;
		width: 139px;
		height: 35px;
	}
	.only-index{
		&:before{
			background-image: url('../images/detalhe-rodape.png');
		}
	}
	.only-internas{
		display: none;
		&:before{
			background-image: url('../images/detalhe-rodape-internas.png');
		}
	}

	&:before{
		content: '';
		position: absolute;
		top: 0;
		left: 50%;
		transform: translateX(-50%);
		background-image: url('../images/detalhe-rodape.png');
		background-repeat: no-repeat;
		z-index: 2;
		width: 139px;
		height: 35px;
	}

	.go-topo{
		position: absolute;
		top: 3px;
		z-index: 3;
		left: 50%;
		transform: translateX(-50%) rotate(0deg);
		transition: all 0.3s linear;

		&:hover{
			transform: translateX(-50%) rotate(180deg);
		}
	}

	.faixa-rodape{
		background: repeating-linear-gradient(-45deg, #fa6a00, #fa6a00 3px, #ac4900 6px);
		width: 39.32291666666667%;
		height: 12px;
		margin-left: auto;
	}

	.informacoes{
		font-family: $ubu-medium;
		font-size: 15px;
		line-height: 1.3;

		.telefones{
			.item{
				display: flex;
				align-items: center;

				i{
					margin-right: 5px;
				}
			}
		}
		
	}

	.autorais{
		font-family: $ops-regular;
		font-size: 10px;
		line-height: 1;
		text-align: center;
		padding-top: 15px;
		margin-top: 25px;
		border-top: 1px solid rgba(255,255,255,0.1);

		p{
			margin-bottom: 5px;
		}
		img{
			margin-left: 5px;
		}
	}

	@include media-breakpoint-up(lg) {
		.faixa-rodape{
			margin-bottom: -12px;
		}
	}
	@include media-breakpoint-up(md) {
		.informacoes{
			display: flex;
		    flex-direction: column;
		    justify-content: flex-end;

		    .dados{
		    	margin-bottom: 20px;
		    }
		}
	}
	@include media-breakpoint-up(sm) {
		.informacoes{
			text-align: right;
			
		    .telefones{
		    	display: flex;
		    	align-items: center;
		    	justify-content: flex-end;

		    	.endereco{
		    		width: 320px;
		    		max-width: 100%;
		    		margin-left: auto;
		    	}

		    	.item{
		    		justify-content: flex-end;
		    	}
		    	.item + .item{
		    		margin-left: 10px;
		    		padding-left: 10px;
		    		position: relative;

		    		&:before{
		    			content: '-';
		    			position: absolute;
		    			top: 50%;
		    			transform: translateY(-50%);
		    			left: -3px;
		    			line-height: 1;
		    		}
		    	}
		    }
		    .redes-sociais{
		    	justify-content: flex-end;
		    }
		}
	}
	@include media-breakpoint-only(sm) {
		.logo{
			margin-top: 30px;
			text-align: center;
		}
		.informacoes{
			display: flex;
		    flex-direction: column;
		    justify-content: space-around;
		}
	}
	@include media-breakpoint-only(xs) {
		text-align: center;
		.logo{
			margin-top: 30px;
			margin-bottom: 30px;
		}
		.informacoes{
			.dados{
				margin: 10px 0;
			}
			.telefones{
				.item{
					justify-content: center;
				}
			}
			.redes-sociais{
				justify-content: center;
			}
		}
	}
}