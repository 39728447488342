.btn-expanded{
	padding: .375rem 1.5rem;

	&.btn-sm{
		padding: .25rem 1rem;
	}

	&.btn-lg{
		padding: .5rem 2rem;
	}
}

.btn-pill{
	border-radius: 1.5rem;

	&.btn-sm{
		border-radius: 1rem;
	}

	&.btn-lg{
		border-radius: 2rem;
	}
}
// #go-topo{
//     position: fixed;
//     bottom: 10px;
//     right: 10px;
//     z-index: 995;
//     display: flex;
//     align-items: center;
//     justify-content: center;
//     width: 34px;
//     height: 34px;
//     transform: translateY(80px);
//     background-color: #202020;
//     box-shadow: 0 0 6px rgba(#000, 0.6);
//     transition: all .4s ease-out;

//     &:hover{
//     	background-color: #000;
//     }
// }
// #go-topo.active{
//     transform: translateY(0);
// }

.btn-padrao{
	font-size: 14px;
	font-family: $ubu-bold;
	line-height: 1;
	display: flex;
	align-items: center;
	justify-content: center;
	position: relative;
	border-radius: 17px;
	transition: all 0.3s linear;
	border: none;
	outline: none;

	&:before{
		content: '';
		position: absolute;
		top: -3px;
		left: -3px;
		width: calc(100% + 6px);
		height: calc(100% + 6px);
		border-radius: 20px;
		transition: all 0.3s linear;
		border: 1px solid transparent;
	}
	
	&.w134h34{
		width: 134px;
		height: 34px;
		
	}

	&.laranja{
		background-color: $laranja;
		color: #fff;

		&:before{
			border-color: $laranja;
		}
	}

	&.preto{
		background-color: #000;
		color: #fff;

		&:before{
			border-color: #000;
		}
	}

	&:hover{
		&.laranja{
			background-color: #fff;
			color: #000;

			&:before{
				border-color: #fff;
			}
		}
		&.preto{
			background-color: $laranja;

			&:before{
				border-color: $laranja;
			}
		}
	}
}