body{
	font-family: $ubu-regular;
}
body,
html{
	width:100%;
	min-height:100vh;
}
img[style]{
    max-width: 100%;
    height: auto !important;
}
#app{
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	overflow: hidden;

	.wrapper{
		flex-grow:1;
	}
}
button{
	outline: none;
}
textarea{
	resize: none;
}
figure{
	margin: 0;
}
h1,h2,h3,h4,h5,h6,p{
	margin: 0;
}
a{
    color: inherit;
}
a:focus,
a:hover{
    color: inherit;
    text-decoration: none;
}
*[data-animate]{
	opacity: 0;
}
.animated[data-animate]{
	opacity: 1;
}
.bg-menu{
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 999;
	display: none;
	background-color: rgba(#000, 0.6);
}

.redes-sociais{
	display: flex;
	align-items: center;

	a{
		width: 30px;
		height: 30px;
		font-size: 18px;
		color: #000;
		background-color: #fff;
		border-radius: 50%;
		display: flex;
		align-items: center;
		justify-content: center;
		transition: all 0.3s linear;
		box-shadow: inset 0 0 0px 0px $laranja;

		&:hover{
			color: #fff;
			box-shadow: inset 0 0 0px 15px $laranja;
		}
	}
	a + a{
		margin-left: 5px;
	}
}

.titulo-fundo{
	position: relative;

	.h1-fundo{
		position: absolute;
		top: 0;
		color: #ededed;
		font-family: $ubu-bold;
		line-height: 1;
	}
}

.h2-padrao{
	font-size: 30px;
	color: $laranja;
	font-family: $ubu-bold;
	line-height: 1;

	&.detail{
		position: relative;
		padding-bottom: 30px;
		margin-bottom: 25px;
		z-index: 2;

		&:after{
			content: '';
			position: absolute;
			bottom: 0;
			left: 0;
			width: 32px;
			height: 4px;
			background-color: $laranja;
			border-radius: 2px;
		}
	}
}

.desc{
	font-size: 14px;
	line-height: 1.2;

	&.editor{
		p + p{
			margin-top: 14px;
		}
	}

	iframe{
		max-width: 100%;
	}
}

.owl-carousel{

	.owl-prev,
	.owl-next{
		background-color: #ededed;
		color: #111;
		width: 25px;
		height: 25px;
		font-size: 12px;
		display: flex;
		align-items: center;
		justify-content: center;
		border: 1px solid #000;
		border-radius: 50%;
		box-shadow: 0 0 0 6px #ededed;
		transition: all 0.4s ease-out;

		&:hover{
			background-color: $laranja;
			box-shadow: 0 0 0 6px $laranja;
			color: #fff;
			border-color: #fff;
		}
	}
}