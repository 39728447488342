.form-estilos{

	input{
		min-height: 40px;
	}

	.form-control{
		background-color: #ededed;
		font-size: 14px;
		color: #666;
		font-family: $ubu-bold;
		border: 1px solid transparent;
		border-radius: 20px;
		padding-left: 20px;

		&::placeholder{
			color: #666666;
		}

		&:focus{
			box-shadow: none;
			border-color: $laranja;
		}
	}

	.custom-input-file{
		position: relative;

		input{
			&:focus{
				border-color: transparent;
			}
		}

		label{
			position: absolute;
			left: 0;
			top: 0;
			width: 100%;
			height: 100%;
			display: flex;
			align-items: center;

			.output{
				flex-grow: 1;
				white-space: nowrap;
				text-overflow: ellipsis;
				height: 100%;
				display: flex;
				align-items: center;
				padding-left: 20px;
				background-color: #ededed;
				border-top-left-radius: 20px;
				border-bottom-left-radius: 20px;
				font-size: 14px;
				color: #666;
				font-family: $ubu-bold;
				max-width: 100%;
				overflow: hidden;
				height: 36px;

				@include media-breakpoint-only(xs) {
					&.up-sm{
						display: none;
					}
				}
				@include media-breakpoint-up(sm) {
					&.only-xs{
						display: none;
					}
				}
			}
			.botao{
				background-color: #000;
				color: #fff;
				font-size: 14px;
				font-family: $ubu-bold;
				height: 40px;
				border-radius: 20px;
				display: flex;
				align-items: center;
				justify-content: center;
				width: 105px;
				cursor: pointer;
				transition: all 0.4s ease-out;

				&:hover{
					background-color: $laranja;
				}
			}
		}
	}
}