.icon{
	background-image: url('#{$imgs}/sprite.png');
	background-repeat:no-repeat;
	display: inline-block;
	vertical-align: middle;
	background-size: 58px 32px;
	
	&.brazil{
		width: 32px;
		height: 32px;
		background-position: 0px 0px;
	}
	&.whatsrodape{
		width: 16px;
		height: 16px;
		background-position: -42px 0px;
	}
}


