.internas{
	padding-top: 40px;
	padding-bottom: 40px;

	.internas-header{
		text-align: center;
		font-family: $ubu-bold;
		padding-bottom: 40px;
		position: relative;
		margin-bottom: 30px;

		.h1-internas{
			color: #ededed;
			line-height: 1;
		}
		.h2-internas{
			color: $laranja;
			width: 100%;
			max-width: 640px;
			margin-left: auto;
			margin-right: auto;
			line-height: 1;
			font-size: 30px;

		}

		&:after{
			content: '';
			position: absolute;
			bottom: 0;
			left: 50%;
			transform: translateX(-50%);
			width: 33px;
			height: 4px;
			background-color: $laranja;
			border-radius: 2px;
		}

		@include media-breakpoint-only(xl) {
			.h1-internas{
				font-size: 100px;
			}
			.h2-internas{
				margin-top: -30px;
			}
		}
		@include media-breakpoint-only(lg) {
			.h1-internas{
				font-size: 80px;
			}
			.h2-internas{
				margin-top: -30px;
			}
		}
		@include media-breakpoint-only(md) {
			.h1-internas{
				font-size: 70px;
			}
			.h2-internas{
				margin-top: -25px;
			}
		}
		@include media-breakpoint-only(sm) {
			.h1-internas{
				font-size: 50px;
			}
			.h2-internas{
				margin-top: -20px;
				font-size: 24px;
			}
		}
		@include media-breakpoint-only(xs) {
			.h1-internas{
				font-size: 30px;
			}
			.h2-internas{
				margin-top: -10px;
				font-size: 18px;
			}
		}
	}
}

.pag-assistencia{
	.desc{
		margin-bottom: 40px;
	}
	.btn-padrao{
		margin: 30px auto 0 auto;
	}
}

.pag-contato{
	.btn-padrao{
		margin-left: auto;
	}

	@include media-breakpoint-down(md) {
		.btn-padrao{
			margin-right: auto;
		}
		.desc{
			margin-top: 40px;
		}
	}
}

.pag-locacao-internas{
	.album-locacao{
		width: 445px;
		max-width: 100%;
		margin-left: auto;
		margin-right: auto;
	}
	#owlLocacao{
		margin-top: 10px;
		padding-bottom: 30px;
		.owl-nav{
			position: absolute;
			bottom: 0;
			left: 50%;
			transform: translateX(-50%);
			width: 90px;

			.owl-prev,
			.owl-next{
				position: absolute;
				top: 0;
				transform: translateY(-50%);
			}
			.owl-prev{
				left: 0;
			}
			.owl-next{
				right: 0;
			}
		}
	}

	@include media-breakpoint-down(md) {
		.album-locacao{
			margin-bottom: 30px;
		}
	}
}

.table-categorias{
	background-color: #ededed;
	border: 1px solid #ddd;

	.table-header{
		background-color: $laranja;
		text-align: center;
		color: #fff;
		font-size: 24px;
		font-family: $ubu-bold;
		line-height: 1;
		padding: 15px 5px;
	}
	.botao-cat{
		background-color: #ccc;
		border: none;
		display: block;
		text-align: left;
		font-family: $ubu-medium;
		font-size: 16px;
		color: #000;
		width: 100%;
		padding-left: 10px;
		min-height: 40px;
		outline: none;
	}
	.table-itens{
		list-style-type: none;
		padding-left: 0;
		margin-bottom: 0;

		a{
			padding-left: 15px;
			font-size: 14px;
			min-height: 30px;
			display: flex;
			align-items: center;
			transition: all 0.3s linear;
			color: #222;

			&:hover{
				background-color: #ddd;
			}
		}
	}

	@include media-breakpoint-down(md) {
		margin-bottom: 40px;
		.table-itens{
			li + li{
				border-top: 1px solid #ddd;
			}
		}
	}
}

.carouselAlbum{
	margin-top: 30px;
	padding-bottom: 30px;
	.owl-nav{
		position: absolute;
		bottom: 0;
		left: 50%;
		transform: translateX(-50%);
		width: 90px;

		.owl-prev,
		.owl-next{
			position: absolute;
			top: 0;
			transform: translateY(-50%);
		}
		.owl-prev{
			left: 0;
		}
		.owl-next{
			right: 0;
		}
	}
}

.segura-videos{
	margin-top: 50px;
	.video-exibicao{
		padding-bottom: 56.25%;
	}

	@include media-breakpoint-only(xs) {
		.col-sm-6 + .col-sm-6{
			margin-top: 30px;
		}
	}
}

.btn-padrao.voltar{
	margin-left: auto;
	margin-top: 40px;

	@include media-breakpoint-only(xs) {
		margin-right: auto;
	}
}